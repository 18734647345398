function CommentsStarsPlugin(elementQuery, baseClass, fullClassName, emptyClassName, cb) {
    const commentsStarsEls = document.querySelectorAll(elementQuery);
    commentsStarsEls.forEach((commentsStarsEl) => {
        const starsEls = [];
        const commentRate = parseInt(commentsStarsEl.dataset.rate, 10);
        for (let i = 0; i < commentsStarsEls.length - 1; i++) {
            const starEl = document.createElement("div");
            starEl.dataset.starRate = (i + 1).toString();
            starEl.classList.add(baseClass);
            if (i < commentRate) {
                starEl.classList.add(fullClassName);
            } else {
                starEl.classList.add(emptyClassName);
            }
            starsEls.push(starEl);
            commentsStarsEl.appendChild(starEl);
        }
        if (cb) {
            const mouseoverHandler = (e) => {
                const startRate = parseInt(e.target.dataset.starRate, 10) || 0;
                starsEls.forEach((starEl, idx) => {
                    if (idx < startRate) {
                        starEl.classList.remove(emptyClassName);
                        starEl.classList.add(fullClassName);
                    } else {
                        starEl.classList.add(emptyClassName);
                        starEl.classList.remove(fullClassName);
                    }
                });
            };
            const mouseoutHandler = (e) => {
                starsEls.forEach((starEl, idx) => {
                    if (idx < commentRate) {
                        starEl.classList.remove(emptyClassName);
                        starEl.classList.add(fullClassName);
                    } else {
                        starEl.classList.add(emptyClassName);
                        starEl.classList.remove(fullClassName);
                    }
                });
            };
            const clickHandler = (e) => {
                cb(commentsStarsEl.dataset.commentId, e.target.dataset.starRate || 0);
            }
            commentsStarsEl.addEventListener('mouseover', mouseoverHandler);
            commentsStarsEl.addEventListener('mouseout', mouseoutHandler);
            commentsStarsEl.addEventListener('click', clickHandler);
        }
    });
}
window.CommentsStarsPlugin = CommentsStarsPlugin;
