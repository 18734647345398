
document.addEventListener('DOMContentLoaded', () => {
    let swiper;
    const initSlider = () => {
        const vw =  Math.max(document.documentElement.clientWidth || 0, window.innerWidth || 0);
        const isMobile = vw < 1024;
        const enableTouch = 'ontouchstart' in document;
        swiper = new Swiper('.article-swiper', {
            // loop: true,

            slidesPerView: isMobile ? 1.15 : 3,
            spaceBetween: isMobile ? 10 : 20,

            navigation: isMobile ? false : {
                nextEl: '.swiper-button-next',
                prevEl: '.swiper-button-prev',
            },

            cssMode: enableTouch,
            centeredSlides: isMobile,
        });
    }

    window.onresize = (r) => {
        if(swiper && typeof swiper.destroy === 'function'){
            swiper.destroy();

        }
        initSlider();
    }

    initSlider();

});
