export  function LangDropdownPlugin(elementQuery, cb) {
    const body = document.querySelector('body');
    const langWrapper = document.querySelector(elementQuery);
    if (!langWrapper) {
        console.warn('Language wrapper element not found', elementQuery);
        return;
    }
    const langButton = langWrapper.querySelector('[data-current-lang]');
    if (!langButton) {
        console.warn('Language button element not found ([data-current-lang])');
        return;
    }
    const langButtonText = langButton.querySelector('span')
    const langButtonChevron = langButton.querySelector('.chevron')
    let curLang = langButton.getAttribute('data-current-lang');
    if (!langButton) {
        console.warn('Current language is not set.');
        return;
    }
    let currentLangInList = langWrapper.querySelector(`[data-lang=${curLang}]`);
    if (!currentLangInList) {
        console.warn('Current language not found in the list.');
        return;
    }
    currentLangInList.style.display = 'none';

    const langList = langWrapper.querySelector('.lang-dropdown');
    if (!langButton) {
        console.warn('Languages list not found.');
        return;
    }

    const langEls = langList.querySelectorAll('[data-lang]');
    if (!langEls.length) {
        console.warn('Languages list not found.');
        return;
    }

    let isOpen = false;

    function openDropDown() {
        langList.classList.add('open');
        isOpen = true;
        langButtonChevron.style.transform = 'rotate(180deg)';
    }

    function closeDropDown() {
        langList.classList.remove('open');
        isOpen = false;
        langButtonChevron.style.transform = 'none';
    }

    function langButtonClickHandler(e) {
        e.preventDefault();
        if (isOpen) {
            closeDropDown();
        } else {
            openDropDown();
        }
        return false;
    }

    function newLangClickHandler(e) {
        e.preventDefault();
        closeDropDown();
        curLang = e.target.dataset.lang;
        langButtonText.innerText = e.target.innerText;
        setTimeout(() => {
            currentLangInList.style.display = 'block';
            currentLangInList = langWrapper.querySelector(`[data-lang=${curLang}]`);
            currentLangInList.style.display = 'none';
        }, 250);
        cb && cb(curLang);
        return false;
    }

    function closeDropdownOnEventHandler() {
        body.removeEventListener('click', closeDropdownOnEventHandler);
        document.removeEventListener('scroll', closeDropdownOnEventHandler);
        closeDropDown();
    }

    function mouseoutHandler(e) {
        if (isOpen) {
            body.addEventListener('click', closeDropdownOnEventHandler, { once: true });
            document.addEventListener('scroll', closeDropdownOnEventHandler, { once: true });
        }
    }

    function mouseoverHandler(e) {
        if (isOpen) {
            body.removeEventListener('click', closeDropdownOnEventHandler);
            document.removeEventListener('scroll', closeDropdownOnEventHandler);
        }
    }

    langWrapper.addEventListener('mouseout', mouseoutHandler);
    langWrapper.addEventListener('mouseover', mouseoverHandler);

    langButton.addEventListener('click', langButtonClickHandler);

    /*langEls.forEach(el => {
        el.addEventListener('click', newLangClickHandler);
    });*/
}

window.LangDropdownPlugin = LangDropdownPlugin;
