function AccordionPlugin(elementQuery) {
    const duration = 300;
    const accElement = document.querySelector(elementQuery);
    if (!accElement) {
        console.warn('Accordion element not found', elementQuery);
        return;
    }
    new Accordion(accElement, {
        duration,
        showMultiple: true,
        openOnInit: [0],
        beforeOpen: (curEl) => {
            const chevronElement = curEl.querySelector('.chevron');
            chevronElement.style.transform = `rotate(180deg)`;
        },
        beforeClose: (curEl) => {
            const chevronElement = curEl.querySelector('.chevron');
            chevronElement.style.transform = `none`;
        },
    });
}
window.AccordionPlugin = AccordionPlugin;
