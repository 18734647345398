(() => {
    const detectActiveLink = (e) => {
        let hash = 'terms';
        if (e) {
            hash = e.destination.url.split('#')[1] || hash;
        } else {
            hash = window.location.hash ? window.location.hash.replace('#', '') : hash;
        }
        let activeLinkIdx = 0;
        console.log(hash);
        switch (hash) {
            case 'privacy':
                activeLinkIdx = 1;
                break;
            case 'refund':
                activeLinkIdx = 2;
                break;
            default:
                activeLinkIdx = 0;
                break;
        }
        const link = document.querySelectorAll('.terms-wrapper .menu-panel a');
        link.forEach((link, idx, els) => {
            if (idx === els.length - 1) return;
            const contentEl = document.querySelector(`#terms-content-${idx}`);
            if (idx === activeLinkIdx) {
                link.classList.add('active');
                contentEl.style.display = 'block';
            } else {
                link.classList.remove('active');
                contentEl.style.display = 'none';
            }
        });
    }

    document.addEventListener('DOMContentLoaded', () => {
        AccordionPlugin('.faq-questions');
        navigation.addEventListener('navigate', detectActiveLink);
        detectActiveLink();
    });
})();


