(() => {
    const changeLanguageHandler = (langCode) => {
        console.log('Language code:', langCode);
    }
    const mobileMenuHandler = (menu) => {
        const bodyEl = document.querySelector('body');
        const mobileMenuButton = document.querySelector('.mobile-menu-button');
        let isOpen = false;
        mobileMenuButton.addEventListener('click', (e) => {
            if (isOpen) {
                mobileMenuButton.classList.remove('opened');
                bodyEl.classList.remove('mobile-menu-opened');
            } else {
                mobileMenuButton.classList.add('opened');
                bodyEl.classList.add('mobile-menu-opened');
            }
            isOpen = !isOpen;
        });
    }
    document.addEventListener('DOMContentLoaded', () => {
        LangDropdownPlugin('.lang-wrapper-header-desktop', changeLanguageHandler);
        LangDropdownPlugin('.lang-wrapper-header-mobile', changeLanguageHandler);
        LangDropdownPlugin('.lang-wrapper-footer', changeLanguageHandler);
        mobileMenuHandler();
        AccordionPlugin('.faq-questions');
    });
})();
