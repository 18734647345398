(() => {
    const commentRatedHandler = (commentId, rate) => {
        alert(`Comment #${commentId} rated: ${rate}`);
    }
    document.addEventListener('DOMContentLoaded', () => {
        // pass callback as last argument to enable rating
        AccordionPlugin('.faq-questions');
        const vw =  Math.max(document.documentElement.clientWidth || 0, window.innerWidth || 0)
        if (vw < 1024) {
            new Swiper('.map-mobile-slider .swiper', {
                direction: 'horizontal',
                loop: true,
                pagination: {
                    el: '.swiper-pagination',
                },
                spaceBetween: 10,
                cssMode: true,
            });
            new Swiper('.plates-swiper.swiper', {
                direction: 'horizontal',
                spaceBetween: 12,
                slidesPerView: 1.15,
                cssMode: true,
                centeredSlides: true,
            });
        }
        let swiper;
        const initSlider = () => {
            const vw =  Math.max(document.documentElement.clientWidth || 0, window.innerWidth || 0);
            const isMobile = vw < 1024;
            const enableTouch = 'ontouchstart' in document;
            if (isMobile) {
                new Swiper('.map-mobile-slider .swiper', {
                    direction: 'horizontal',
                    loop: true,
                    pagination: {
                        el: '.swiper-pagination',
                    },
                    spaceBetween: 10,
                    cssMode: enableTouch,
                });
                new Swiper('.plates-swiper.swiper', {
                    direction: 'horizontal',
                    spaceBetween: 12,
                    slidesPerView: 1.15,
                    cssMode: enableTouch,
                    centeredSlides: true,
                });
            }
        }

        window.onresize = (r) => {
            swiper && swiper.destroy();
            initSlider();
        }

        initSlider();
    });
})();
