function TabsPlugin(elementQuery, cb) {
    const wrapper = document.querySelector(elementQuery);
    if (wrapper) {
        const tabs = wrapper.querySelectorAll('[data-tab]');
        function clickHandler(e) {
            if (!e.target.classList.contains('tab-active')) {
                for (let i = 0; i < tabs.length; i++) {
                    tabs[i].classList.remove('tab-active');
                    e.target.classList.add('tab-active');
                }
                cb(e.target.getAttribute('data-index'));
            }
        }
        for (let i = 0; i < tabs.length; i++) {
            tabs[i].addEventListener('click', clickHandler);
            tabs[i].addEventListener('touch', clickHandler);
        }
    }
}

window.TabsPlugin = TabsPlugin;
